<template>
  <div v-if="!selectPermission">
    <no-items
      :title="'매출 정보 접근 권한이 없습니다.'| translate"
    />
  </div>
  <div
    v-else
    style="height: 100%;"
  >
    <div class="flex flex_end mr-4">
      <m-switches
        v-model="isOnlyBalance"
        :styles="switchStyle"
        :label="'미수 금액만 보기' | translate"
      />
    </div>
    <div
      style="height: calc(100% - 320px); overflow-y: hidden !important;"
    >
      <template v-if="!loading">
        <template v-if="showItems.length > 0">
          <billing-list-item-selectable
            :items="showItems"
            @update:selectedItems="v => this.selectedItems = v"
          />
        </template>
        <template v-else>
          <no-items
            :title="noItemText"
          />
        </template>
      </template>
      <template v-else>
        <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
          <progress-loading />
        </div>
      </template>
    </div>
    <!-- 기타 수납 처리 버튼 -->
    <div style="position: absolute; bottom: 50px; left: 0; right :0;">
      <template v-if="!loading">
        <template v-if="showItems.length > 0">
          <etc-button
            :selected-items="selectedItems"
            :account-id="accountId"
            @click:request="onClickRequest"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import Billing from "@/model/billing";
    import BillingListItemSelectable from "@/components/items/billing/BillingListItemSelectable";
    import NoItems from "@/components/items/NoItems";
    import EtcButton from "@/components/items/billing/sub/EtcButton";
    import now from "@/assets/plugins/mps-common/time/now";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import MSwitches from "../../../../comp/MSwitches";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
        name: "BalanceTabsItems",
        components: {MSwitches, ProgressLoading, EtcButton, NoItems, BillingListItemSelectable},
        props: {
            accountId: {
                type: Number,
                default: undefined,
            },
            changeId: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                loading: false,
                items: [],
                selectedItems: [],
                loadingUpdate: false,
                isOnlyBalance : true,
                switchStyle : {
                  label : {
                    fontSize : '13px',
                  },
                }
            };
        },
        computed : {
          showItems(){
            if(this.items.length > 0){
              return this.isOnlyBalance
                      ? this.items.filter( i => i._item.balance > 0)
                      : this.items
            } else {
              return [];
            }
          },
          noItemText(){
            return this.isOnlyBalance
                    ? this.$translate('미수금이 없습니다.')
                    : this.$translate('매출내역이 없습니다')
          },
          selectPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_BILLING_MANAGE)
          }
        },
        watch: {
            changeId() {
                this.isOnlyBalance = true;
                this.loadBillingWithBalance(true);
            },
        },
        created() {
            this.isOnlyBalance = true;
            this.loadBillingWithBalance(true);
        },
        methods: {
            /**
             * 기타 수납 처리 버튼을 클릭하였습니다.
             */
            async onClickRequest(etcForm) {
                if (this.selectedItems.length === 0) return this.$alert(this.$translate("기타수납할 매출을 선택해주세요"));


                this.loadingUpdate = true;
                try {
                    await this.$requestWithOverlay(PATH.CMS.CMS_REQUEST_ADD_ETC)
                        .setObject(etcForm)
                        .enqueue();
                    this.$snackbar(this.t("출금신청에 성공하였습니다."));
                    try {
                        this.loadBillingWithBalance(false);
                        this.notifyEvent(this.mpsEvents.ON_ETC_REQUEST_SUCCESSED);
                    } catch (e) {
                    }
                } catch (error) {
                    this.$snackbarError(this.$translate("출금신청중 에러가 발생하였습니다"));
                }

                this.loadingUpdate = false;
            },

            async loadBillingWithBalance(loading = true) {
                if (!this.accountId) return;
                if (this.loading) return;
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.BILLING.LIST)
                        .setObject({accountId: this.accountId})
                        .wait(500)
                        .enqueue();
                    this.items = response.data.map(item => new Billing(item));
                } catch (error) {
                    console.error(error);
                    // 실패 메시지를 사용자에게 보여주지 않습니다.
                }
                this.loading = false;
            },
        },
    }
</script>

<style scoped>
</style>