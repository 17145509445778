<template>
  <v-list
    three-line
    class="py-0"
    flat
    style="height: 100%; overflow-y: auto;"
  >
    <v-list-item-group
      multiple
    >
      <div
        v-for="(item,index) in innerItems"
        :key="item.raw.billingId"
        class="boder-bottom"
      >
        <v-row class="px-0 mx-0 align-center">
          <v-col
            cols="2"
            class="pt-3"
          >
            <v-checkbox
              v-model="item.raw.selected"
              @click.stop.prevent="onClickCheckbox(item)"
            />
          </v-col>
          <v-col
            cols="10"
            class="py-0 pl-0"
          >
            <v-list-item
              v-ripple="false"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-row
                    justify="space-between"
                    class="mx-0"
                  >
                    <div>{{ item.raw.salesMonth | month }}</div>
                    <div class="color__blue">
                      {{ item.raw.item }}
                    </div>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-row
                    justify="space-between"
                    class="mx-0"
                  >
                    <div>
                      {{ '매출금액' | translate }} : {{ item.raw.reqPrice | currency }}{{ '원' | translate
                      }}
                    </div>
                    <div>&nbsp;</div>
                  </v-row>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-row
                    class="mx-0 d-flex flex-column align-start"
                  >
                    <div>
                      {{ '납부금액' | translate }} : {{ item.raw.price | currency }}{{ '원' |
                        translate
                      }}
                    </div>
                    <div class="color__red">
                      {{ '잔액' | translate }} : {{ item.raw.balance | currency }}
                    </div>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    import Billing from "@/model/billing";

    export default {
        name: "BillingListItemSelectable",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                selectedItemsKey: [],
                innerItems: [],
            }
        },
        computed: {
            computedSelectedItems() {
                return this.innerItems.filter(item => item.raw.selected);
            },
        },
        watch: {
            computedSelectedItems() {
                this.$emit('update:selectedItems', this.computedSelectedItems);
            },

            items() {
                this.initItems();
            },
        },
        created() {
            this.initItems();
        },
        mounted() {
            this.initItems();
        },
        methods: {
            initItems() {
              const clone = JSON.parse(JSON.stringify(this.items));
              const selected = clone.map(item => { item._item.selected = false; return item._item; });
              this.innerItems = selected.map(item => new Billing(item));
            },

            onClickCheckbox(item) {
                let index = this.selectedItemsKey.indexOf(item.raw.billingId);
                if (index > -1) {
                    this.selectedItemsKey.splice(index, 1);
                    item.raw.selected = false;
                } else {
                    this.selectedItemsKey.push(item.raw.billingId);
                    item.raw.selected = true;
                }
            },
        },
    }
</script>

<style scoped>
  >>>.v-input--selection-controls {
    margin-top: 6px;
    padding-top: 4px;
  }
</style>