export default class Contract {

    _contract;

    constructor(contract) {
        this._contract = contract;
    }

    get raw() {
        return this._contract;
    }

    get contractTypeName() {
        return this._contract.contractType === "1"  ? "신규" : this._contract.contractType === "2" ? "재계약" : "";
    }
}