<template>
  <v-list
    three-line
    flat
    class="pb-12"
  >
    <v-list-item-group>
      <div
        v-for="(item,index) in items"
        :key="item.raw.workorderStateHistoryId"
        @click="onClick(item)"
      >
        <v-list-item
          v-ripple="false"
          class="pa-0"
          :style="{'background': Number(item.raw.contractState) === 3 ? '#efefef' : '#fff'}"
        >
          <v-list-item-content
            class="px__18 pt-0 pb-5 pt-3"
          >
            <v-list-item-title>
              <v-row
                justify="space-between"
                class="ma-0 pa-0"
              >
                <div>
                  <span class="font__17">{{ item.raw.contractStartdate | date }} ~ {{ item.raw.contractEnddate | date }}</span>
                </div>

                <div
                  class="font__16"
                  :class="Number(item.raw.contractState) === 3 ? 'color__red' : 'color__333'"
                >
                  {{ Number(item.raw.contractState) === 3 ? '해지된 계약서' : item.raw.realYnName }}
                </div>
              </v-row>
            </v-list-item-title>

            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 color__333 py__3"
              >
                <div>
                  {{ '계약번호' | translate }} : {{ item.raw.contractNo }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 color__333 py__3"
              >
                <div>
                  {{ '보증금' | translate }} : {{ item.raw.contractAmount | currency }}{{ '원' | translate }}
                </div>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </div>
      <div
        style="height: 40px; background: #0c98fe; line-height: 40px; margin: 5px; color: #fff; font-weight: bold;"
        @click="onClick()"
      >
        {{ '계약서 추가' | translate }}
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import {ETC} from "@/constants/etc-code";
    import Permissions from "@/constants/permissions";

    export default {
        name: "ContractListItem",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
            }
        },
      computed: {
        accountEditPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
        }
      },
        methods: {
          onClick(item) {
            if(!this.accountEditPermission && !item) {
              return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
            }
            this.$emit("click:contract", item);
          },
        }
    }
</script>

<style scoped>

</style>