<template>
  <v-list
    three-line
    flat
    class="pa-0 pb-12"
  >
    <v-list-item-group>
      <div
        v-for="(item,index) in items"
        :key="item.raw.workorderId"
      >
        <v-list-item
          v-ripple="false"
          class="px-0"
          @click="onClick(item)"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 color__333 align-center"
              >
                <div class="font__16">
                  {{ '등록일' | translate }} : {{ item.raw.workorderReceiptDate | date }}
                </div>

                <div class="color__blue font__16">
                  {{ item.raw.workorderStateName }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="item.isWorkorderStateCompleted || item.isWorkorderStateCanceled">
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 black--text"
              >
                <div class="color__red">
                  {{ '처리일' | translate }} : {{ item.raw.doneDate | date }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="!!item.raw.printerModelName">
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 black--text"
              >
                <div>
                  {{ item.raw.printerModelName }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="!item.isWorkorderStateReceived">
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 black--text"
              >
                <div>
                  {{ 'AS 기사' | translate }} : {{ item.raw.staffName }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="!!item.raw.workorderContent">
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 black--text"
              >
                <div style="max-height: 70px; text-align: left; line-height: 1.7">
                  {{ '접수증상' | translate }} : {{ item.raw.workorderContent }}
                </div>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="(item.isWorkorderStateCompleted || item.isWorkorderStateCanceled)"
            >
              <v-row
                justify="space-between"
                class="mx-0 px__18 py__3 black--text"
              >
                <div style="max-height: 70px; text-align: left; line-height: 1.7">
                  {{ '처리내역' | translate }} : {{ item.raw.asContent }}
                </div>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "AsLatestListItem",
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    onClick(item) {
      if (this.$route.name === 'AsDetailForLatest') {
        this.historyManager.guard();
        setTimeout(() => {
          this.routerManager.replace({
            name: "AsDetailForLatest", params: {workorderId: item.raw.workorderId,},
          });
        }, 300);
      } else {
        this.backGuard.pop();
        this.routerManager.push({
          name: "AsDetailForLatest", params: {workorderId: item.raw.workorderId,},
        });
      }
    },
  },
}
</script>

<style scoped>
</style>