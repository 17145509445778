export default class ServerFile {
    keys;
    refId;
    refType;
    _originalFilename;
    path;
    uploadPath;
    regDatetime;

    get validate() {
        return !!this.keys.fileId;
    }

    equals(o) {
        if (!o.fileId) return false;
        return this.keys.fileId === o.fileId;
    }

    constructor(s) {
        if (!s) return;
        if (s.keys) this.keys = s.keys;
        if (s.refId) this.refId = s.refId;
        if (s.refType) this.refType = s.refType;
        if (s.originalFilename) this._originalFilename = s.originalFilename;
        if (s.path) this.path = s.path;
        if (s.uploadPath) this.uploadPath = s.uploadPath;
        if (s.regDatetime) this.regDatetime = s.regDatetime;
    }

    set originalFilename(originalFilename) {
        this._originalFilename = originalFilename;
    }

    get originalFilename() {
        if (!!this._originalFilename) {
            return this._originalFilename;
        } else {
            return "No File Name";
        }
    }

    clone() {
        const newServerFile = new ServerFile();
        newServerFile.keys = this.keys;
        newServerFile.refId = this.refId;
        newServerFile.refType = this.refType;
        newServerFile.originalFilename = this.originalFilename;
        newServerFile.path = this.path;
        newServerFile.uploadPath = this.uploadPath;
        newServerFile.regDatetime = this.regDatetime;
        return newServerFile;
    }

    get json() {
        return {
            keys: this.keys,
        }
    }
}