<template>
  <ul>
    <li>
      <a :href="'tel:' + telNo">
        <v-btn
          icon
          :disabled="!hasPhone"
        >
          <img
            v-if="hasPhone"
            src="@/assets/images/main/svg/call.svg"
          >
          <img
            v-else
            src="@/assets/images/main/svg/grey_call.svg"
          >

        </v-btn>
      </a>
    </li>
    <li>
      <a :href="'sms:' + telNo">
        <v-btn
          icon
          :disabled="!hasPhone"
        >
          <img
            v-if="hasPhone"
            src="@/assets/images/main/svg/sms.svg"
          >
          <img
            v-else
            src="@/assets/images/main/svg/grey_sms.svg"
          >
        </v-btn>
      </a>
    </li>
    <li>
      <a
        :href="canShowMap ? 'geo:' + lat+','+lng : 'javascript:void(0)'"
      >
        <v-btn
          icon
          :disabled="!canShowMap"
        >
          <img
            v-if="canShowMap"
            src="@/assets/images/main/svg/pin.svg"
          >
          <img
            v-else
            src="@/assets/images/main/svg/grey_pin.svg"
          >

        </v-btn>
      </a>
    </li>
    <li>
      <a>
        <v-btn
          icon
          @click="onAddClicked"
        >
          <img
            src="@/assets/images/main/svg/as.svg"
          >
          <!--          <img-->
          <!--            v-else-->
          <!--            src="@/assets/images/main/svg/grey_as.svg"-->
          <!--          >-->
        </v-btn>
      </a>
    </li>
  </ul>
</template>

<script>
    export default {
        name: "DetailContextMenu",
        props: {
            telNo: {
                type: String,
                default: undefined
            },
            location: {
                type: Object,
                default: undefined
            },
            title: {
                type: String,
                default: "거래처"
            },
          account:undefined,
        },
        computed: {
            lat() {
                return !!this.location ? this.location.lat : 0;
            },
            lng() {
                return !!this.location ? this.location.lng : 0;
            },
            hasPhone() {
                return this.$isNotEmpty(this.telNo);
            },
            canShowMap() {
                if (this.$isNOU(this.lat) || this.$isNOU(this.lng)) return false;
                if (isNaN(this.lat) || isNaN(this.lng)) return false;
                if (this.lat === 0 && this.lng === 0) return false;
                return true;
            }
        },
      methods: {
        onAddClicked() {
          this.routerManager.push({name: "AsRegister", params: {account: this.account}});
        },
      },
    }
</script>

<style scoped>
    ul {
        padding: 15px 10px !important;
        display: flex;
        justify-content: space-around;
    }

    li {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid #aaa;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    >>> .v-btn--icon.v-size--default {
        height: 52px;
        width: 52px;
    }

    img {
        width: 63%;
    }
</style>
