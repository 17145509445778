<template>
  <div :style="styleObjects.root">
    <v-switch
      v-model="inputData"
      color="#2196F3"
      dense
      @change="onChange"
    >
      <template #label>
        <div :style="styleObjects.label">
          {{ label }}
        </div>
      </template>
    </v-switch>
  </div>
</template>

<script>
    export default {
        name: "MSwitches",
        props: {
            value: {
                type: null,
                default: true
            },
            label: {
                type: null,
                default: 'label'
            },
            readonly: {
                type: Boolean,
                default: false
            },
            styles: {
                type: null,
                default: {
                    root: {},
                    label: {},
                }
            },
        },
        data() {
            return {
                inputData: this.value,
                styleObjects: {
                    root: {},
                    label: {},
                }
            }
        },
        watch: {
            value(v) {
                this.inputData = v;
            },
            styles: {
                deep: true,
                handler() {
                    this.setStyle();
                }
            }
        },
        created() {
            this.setStyle();
        },
        methods: {
            onChange(e) {
                this.$emit('input', e);
            },
            setStyle() {
                Object.assign(this.styleObjects.root, this.styles.root)
                Object.assign(this.styleObjects.label, this.styles.label)
            }
        }

    }
</script>

<style scoped>

</style>