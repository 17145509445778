import {PATH} from "@/constants";
import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";
import {vm} from "@/main";

/**
 * 서버에서 내려오는 파일 모델(또는 서버로 보내는 파일 오브젝트를 정의)
 */
export default class Attachment {
    dettached = false; // 서버 삭제 여부
    attached = false; // 신규 첨부 여부
    _serverFile;
    additionalData;
    _image;
    _loadingFail = false;
    imageIndex;

    clone() {
        const newAttachment = new Attachment();
        newAttachment.dettached = this.dettached;
        newAttachment.attached = this.attached;
        newAttachment.additionalData = this.additionalData;
        newAttachment.serverFile = this.serverFile ? this.serverFile.clone() : undefined;
        return newAttachment;
    }

    init() {
        this.attached = false;
        this.dettached = false;
    }

    get hostPath() {
        return PATH.BASE_URL + this._serverFile.path;
    }

    get loadingText() {
        return this._serverFile.originalFilename + " " + vm.$translate("로딩중") + "...";
    }

    get loadingErrorText() {
        return this._serverFile.originalFilename + " " + vm.$translate("로딩실패") + "!";
    }

    get loadingFail() {
        return this._loadingFail
    }

    get isValidImage() {
        return !!this._image;
    }

    nullize() {
        this._image = null;
    }

    async base64() {
        if (!this._image) {
            try {
                const response = await new RequesterBuilder()
                    .path(PATH.RESOURCE.IMAGE_BASE64)
                    .appendParam('path', this._serverFile.path)
                    .catch()
                    .enqueue();
                this._image = 'data:image/png;base64,' + response
                this._loadingFail = false;
            } catch (e) {
                console.error(e);
                this._loadingFail = true;
            }
        }
    }

    async download() {
        if (!!this._serverFile && !!this._serverFile.path) {
            try {
                const url = PATH.RESOURCE.FILE;
                const response = await new RequesterBuilder()
                    .get()
                    .path(url)
                    .appendParam('path', this._serverFile.path)
                    .enqueue();
                if (!response) return
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
                a.download = this.serverFile.originalFilename;
                document.getElementsByTagName("body")[0].appendChild(a);
                a.click();
            } catch (e) {
                console.error(e);
            }
        }
    }

    get image() {
        return this._image;
    }

    constructor(serverFile) {
        this._serverFile = serverFile;
    }

    toggleDettach() {
        this.dettached = !this.dettached;
    }

    dettach() {
        this.dettached = true;
    }

    cancelDettach() {
        this.dettached = false;
    }

    attach() {
        this.attached = true;
    }

    cancelAttach() {
        this.attached = false;
    }

    set serverFile(serverFile) {
        this._serverFile = serverFile;
    }

    get serverFile() {
        return this._serverFile;
    }

    get validate() {
        return !!this.serverFile && this.serverFile.path
    }
}