<template>
  <div
    :active="requestSize > maxSize"
    class="over-max-size-wrap"
  >
    {{ '용량 초과' | translate }} ({{ (requestSize / 1000000).toFixed(2) }}MB / {{ (maxSize / 1000000).toFixed(2) }}MB)
  </div>
</template>

<script>
export default {
  name: "OverMaxSize",
  props: {
    baseParam: {
      type: Object,
      default: undefined
    },
    detachmentsList: {
      type: Array,
      default: () => []
    },
    attachmentsList: {
      type: Array,
      default: () => []
    },
    maxSize: {
      type: Number,
      default: 20000000,
    },
  },
  computed: {
    requestSize() {
      let blobString = JSON.stringify(this.baseParam || {})
      this.detachmentsList?.forEach(e => {
        blobString += JSON.stringify(e || {})
      })

      let result = new Blob([blobString]).size
      this.attachmentsList?.forEach(e => {
        e?.forEach(t => {
          result += t?.size || 0
        })
      })
      return Number(result || 0)
    },
  },
}
</script>

<style scoped>
.over-max-size-wrap{
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: red;
  color: white;
}
.over-max-size-wrap[active=true]{
  display: flex;
}
</style>